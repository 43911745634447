// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import type { RecipeEdges } from '../types';
import Topbar from '../components/Topbar';
import Stack from '../components/Stack';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

type Props = {
  data: {
    recent: {
      edges: RecipeEdges,
    },
    breakfast: {
      edges: RecipeEdges,
    },
  },
};

const IndexTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const pageTitle = siteTitle;

  const recents = data.recent.edges.map(({ node }) => ({
    image: node.frontmatter.photo,
    link: node.fields.slug,
    preTitle: node.frontmatter.titlePrefix,
    title: node.frontmatter.title,
    subTitle: node.frontmatter.topIngredients,
    cooktime: node.frontmatter.about.cookTime,
    id: node.fields.slug.replace(/\//g, '-')
  }));

  const breakfasts = data.breakfast.edges.map(({ node }) => ({
    image: node.frontmatter.photo,
    link: node.fields.slug,
    preTitle: node.frontmatter.titlePrefix,
    title: node.frontmatter.title,
    subTitle: node.frontmatter.topIngredients,
  }));

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Topbar />
      <div className="index__page">
        <div className="index__sidebar">
          <Sidebar />
        </div>
        <div className="content">
          <Stack title="Recent Recipes" items={recents} />
          {/* <hr />
          <Stack title="Breakfast Recipes" items={breakfasts} />
          <hr />
          <Stack title="Breakfast Recipes (Copy)" items={breakfasts} /> */}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate( $postsOffset: Int!) {
    recent: allMarkdownRemark(
      limit: 50
      skip: $postsOffset
      filter: {
        frontmatter: { template: { eq: "recipe" }, draft: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            about {
              cookTime
            }
            title
            photo
            date
            titlePrefix
            category
            topIngredients
          }
        }
      }
    }
    breakfast: allMarkdownRemark(
      limit: 4
      skip: 0
      filter: {
        frontmatter: {
          categories: { regex: "/breakfast/i" }
          template: { eq: "recipe" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            photo
            date
            titlePrefix
            category
            topIngredients
          }
        }
      }
    }
  }
`;

export default IndexTemplate;
